<template>
    <div class="form-group">
        <label :for="fieldId">
            <input v-bind:value="value" :checked="value"  @change="$emit('change', $event.target.checked)  " v-on:change="$emit('input', $event.target.value)" :class="{ 'is-invalid': valueErrors.length }" :id="fieldId" type="checkbox" :disabled="disabled" />
            {{ title }}
        </label>
        <div class="invalid-feedback" v-for="(text, i) in valueErrors" :key="i">{{ text }}</div>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props:['value', 'name', 'title', 'errors', 'disabled'],
    watch: {
        errors(obj) {
            this.valueErrors = obj.hasOwnProperty(this.name) ? obj[this.name] : [];
        }
    },
    data() {
        return {
            fieldId: null,
            valueErrors: []
        }
    },
    mounted() {
        this.fieldId = Math.random().toString(36).replace(/[^a-z]+/g, '');
    }
}
</script>