<template>
  <div>
    <canvas :id="chartId"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js';
import { randString } from '../mixins/rand-string.mixin';

export default {
  mixins: [randString],
  data() {
    return {
      chartId: this.getRandStr(8),
      myChart: null,
    }
  },
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    render() {
      this.myChart = new Chart(document.getElementById(this.chartId), {
        type: 'line',
        data: this.chartdata,
        options: this.options,
      });

      this.myChart.update();
    }
  },
  mounted() {
    this.render();
  }
}
</script>