<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <div v-if="order" class="row mt-2">
                <div class="col-2">
                    <router-link :to="{ name: 'Orders', query: { stage: activeStage, page: current_page }}">Back to Orders</router-link>
                </div>
                <div class="col-10">
                    <button v-if="stages.inProcess == order.stage" class="btn btn-secondary float-right mr-2" @click="pausePromotion(order.id)">Pause</button>
                    <button v-if="stages.discussign == order.stage || stages.paused == order.stage" class="btn btn-danger float-right mr-2" @click="cancelPromotion(order.id)">Cancel</button>
                    <button v-if="stages.pending == order.stage" class="btn btn-danger float-right mr-2" @click="preCancel(order.id)">Cancel</button>
                    <button v-if="stages.pending == order.stage || stages.paused == order.stage" class="btn btn-primary float-right mr-2" @click="setToPromotion(order.id, stages.paused != order.stage)">Run</button>
                    <button v-if="stages.notPaid == order.stage" class="btn btn-primary float-right mr-2" @click="updateOrder()">Resync</button>
                    <button v-if="stages.discussign == order.stage" class="btn btn-primary float-right mr-2" @click="updatePreCanceledOrder()">Update</button>
                </div>
            </div>
            <div class="row mt-2" v-if="youtubeVideo.youTubeVideoUrl">
                <div class="col-4">
                    <b-img thumbnail fluid :src="youtubeVideo.youTubeVideoUrl" rounded alt="Circle image"></b-img>
                </div>
                <div class="col-8">
                    <h2 class="h3"><a target="_blank" :href="order.youtube_video_url">{{ youtubeVideo.youtubeVideoTitle }}</a></h2>
                    <p>{{ youtubeVideo.youtubeVideoDescription | stringLength(540) }}</p>
                </div>
            </div>
            <div v-if="order" class="row mt-2">
                <div class="col-12">
                    <table class="table table-bordered">
                        <tbody>
                            <tr v-if="stages.inProcess == order.stage || stages.discussign == order.stage || stages.paused == order.stage || stages.pending == order.stage">
                                <td>Notification Status:</td>
                                <td>
                                    <b-form-checkbox switch :value="1" @change="changeStatusOfNotification" v-model="order.is_send_customer_notification_mode" size="lg"></b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>Order Status:</td>
                                <td>{{ stageTextArr[order.stage] }} {{ statusComment ? "(" + statusComment + ")" : '' }}</td>
                            </tr>
                            <tr>
                                <td>Order ID:</td>
                                <td> {{ order.external_order_id }}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{{ order.email }}</td>
                            </tr>
                            <tr v-if="order.phone">
                                <td>Phone:</td>
                                <td>{{ order.phone }}</td>
                            </tr>
                            <tr>
                                <td>Name:</td>
                                <td>{{ order.first_name }} {{ order.last_name }}</td>
                            </tr>
                            <tr>
                                <td>Location:</td>
                                <td>{{ order.city }}, {{ order.country }}</td>
                            </tr>
                            <tr>
                                <td>YouTube Video:</td>
                                <td>{{ order.youtube_video_url }}</td>
                            </tr>
                            <tr>
                                <td>Package Title:</td>
                                <td>{{ order.package_title }}</td>
                            </tr>
                            <tr>
                                <td>Package Views:</td>
                                <td>{{ order.package_views }}</td>
                            </tr>
                            <tr v-if="linkesCurrent">
                                <td>Likes:</td>
                                <td>{{ likesBeforePromo }} <svg style="width: 10px;" xmlns="http://www.w3.org/2000/svg" id="b385ca18-4b85-46d4-bb4b-571c73a19812" data-name="b922a425-e5e0-4ef1-881c-5748460fd139" viewBox="0 0 30.125 18.1738"><title>arrow-icon-size3</title><path d="M29.75,9.3948,21.5281,1.1735a.8479.8479,0,0,0-.601-.261L20.9124.9119a.8526.8526,0,0,0-.5956.2409.8642.8642,0,0,0-.0027,1.23l6.7618,6.7611H.7316a.8566.8566,0,1,0,0,1.7131H27.0759l-6.7591,6.7591a.8576.8576,0,0,0-.0214,1.2113.8462.8462,0,0,0,.61.2583.8949.8949,0,0,0,.621-.2556l8.2233-8.224A.8587.8587,0,0,0,29.75,9.3948Z" transform="translate(0.125 -0.9119)"></path></svg> {{ linkesCurrent }} </td>
                            </tr>
                            <tr v-if="commentsCurrent">
                                <td>Comments:</td>
                                <td>{{ commentsBeforePromo }} <svg style="width: 10px;" xmlns="http://www.w3.org/2000/svg" id="b385ca18-4b85-46d4-bb4b-571c73a19812" data-name="b922a425-e5e0-4ef1-881c-5748460fd139" viewBox="0 0 30.125 18.1738"><title>arrow-icon-size3</title><path d="M29.75,9.3948,21.5281,1.1735a.8479.8479,0,0,0-.601-.261L20.9124.9119a.8526.8526,0,0,0-.5956.2409.8642.8642,0,0,0-.0027,1.23l6.7618,6.7611H.7316a.8566.8566,0,1,0,0,1.7131H27.0759l-6.7591,6.7591a.8576.8576,0,0,0-.0214,1.2113.8462.8462,0,0,0,.61.2583.8949.8949,0,0,0,.621-.2556l8.2233-8.224A.8587.8587,0,0,0,29.75,9.3948Z" transform="translate(0.125 -0.9119)"></path></svg> {{ commentsCurrent }} </td>
                            </tr>
                            <tr v-if="getPromoCodeItem">
                                <td>Discounted price:</td>
                                <td>${{ order.package_price }} - ${{ Math.round((order.package_price - order.payment_total) * 100) / 100 }} ( {{ getPromoCodeItem.type == 0 ? "Discount" : "Promo code"}} - {{ getPromoCodeItem.value }}%) = ${{ order.payment_total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-2">
                <div v-if="stripe_sessions && stripe_sessions.length" class="col-12">
                    <b-button v-b-toggle.collapseid class="m-1 mt-3">Payment Sessions</b-button>
                    <b-collapse id="collapseid">
                        <b-table
                            id="payment_sessions"
                            :items="stripe_sessions"
                            :fields="[
                                { key: 'payment_intent', label: 'PI' },
                                { key: 'date', label: 'Date' },
                                { key: 'status', label: 'Status' },
                            ]"
                            :per-page="stripe_sessions.length"
                            :current-page="1"
                            >
                                <template v-slot:cell(status)="data">
                                    {{ data.item.payment_status }} 
                                </template>
                                <template v-slot:cell(date)="data">
                                    {{ convertBrouserTZ(data.item.created_at) }}
                                </template>
                                <template v-slot:cell(payment_intent)="data">
                                    {{ data.item.payment_intent }}
                                </template>
                            </b-table>
                    </b-collapse>
                </div>
            </div>
            <br>
            <div class="row">
                <div v-if="logs_events.length" class="col-12">
                    <b-tabs>
                        <b-tab v-if="chartdataViews.datasets.length"  title="Views" active>
                            <line-chart ref="viewsChart" style="height: 400px" :chartdata="chartdataViews" :options="options"/>
                        </b-tab>
                        <b-tab v-if="chartdataLikesAndComments.datasets.length"  title="Likes & Comments">
                            <line-chart ref="likesAndCommentsChart" style="height: 400px" :chartdata="chartdataLikesAndComments" :options="options"/>
                        </b-tab>
                        <b-tab title="Logs">
                            <b-table
                            id="orders"
                            :items="logs_events"
                            :fields="log_fields"
                            :per-page="logs_events.length"
                            :current-page="1"
                            >
                                <template v-slot:cell(created_at)="data">
                                    {{ convertBrouserTZ(data.item.date) }}
                                </template>
                                <template v-slot:cell(event)="data">
                                    {{ data.item.from }} -> {{ data.item.to }} 
                                </template>
                                <template v-slot:cell(viwes_total)="data">
                                    {{ data.item.views_total }}
                                </template>
                                <template v-slot:cell(status_comment)="data">
                                    {{ data.item.status_comment }}
                                </template>

                            </b-table>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
            <br>
            <div v-if="customer_orders.length" class="row mt-2">
                <div class="col-12">
                    <h2 class="h2">Other Orders</h2>
                     <b-table
                     id="orders"
                     :items="customer_orders"
                     :fields="fields"
                     :per-page="customer_orders.length"
                     :current-page="1"
                    >
                        <template v-slot:cell(external_order_id)="data">
                            <router-link :to="{ name: 'Order', params: { order_id: data.item.id }}">{{ data.item.external_order_id }}</router-link>
                        </template>
                        <template v-slot:cell(created_at)="data">
                            {{ convertBrouserTZ(data.item.created_at) }}
                        </template>
                        <template v-slot:cell(package_price)="data">
                            ${{ data.item.payment_total }}
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-if="similarOrdersTotal > similarOrdersTake" class="col-12">
                <b-pagination
                     align="center"
                     v-model="similarOrdersCurrent_page"
                     :total-rows="similarOrdersTotal"
                     :per-page="similarOrdersTake"
                ></b-pagination>
            </div>
        </div>
        <pause-window ref="pausePromo" @saved="load"/>
        <cancel-window ref="cancelPromo" @saved="load" />
        <update-order ref="updateOrderNotPaid" @saved="loadAfterUpdate" />
        <pre-cancel-order ref="preCancelOrder" @saved="load" />
        <start-promo-window ref="startPromo" @saved="load" />
        <update-pre-canceled ref="updatePreCanceled" @saved="loadAfterUpdate" />
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import { youtubeVideoPreview } from './../../mixins/youtube-v-preview.mixin';
import lineChart from './../../components/line-chart';
import pauseWindow from './PauseOrder';
import cancelWindow from './CancelOrder';
import updateOrder from './NotPaidOrder';
import preCancelOrder from './PreCancelOrder';
import startPromoWindow from './StartPromo';
import updatePreCanceled from './UpdatePreCanceled';
import { orderProcessStages } from '../../mixins/order-process-stages.mixin';
import { cropText } from './../../mixins/crop-text.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: mapGetters([
      'getPromoCodeItem',
    ]),
    data() {
        return {
            similarOrdersTotal: 0,
            similarOrdersSkip: 0,
            similarOrdersTake: 10,
            similarOrdersCurrent_page: 1,
            activeStage: 0,
            current_page: 1,
            order: null,
            likesBeforePromo: 0,
            linkesCurrent: 0,
            commentsBeforePromo: 0,
            commentsCurrent: 0,
            statusComment: null,
            customer_orders: [],
            process_logs: [],
            stripe_sessions: [],
            logs_events: [],
            chartdataViews: {
                labels: [],
                datasets: [
                    {
                        label: 'Views',
                        backgroundColor: '#f87979',
                        data: []
                    }
                ]
            },
            chartdataLikesAndComments: {
                labels: [],
                datasets: [
                    {
                        label: 'Views',
                        backgroundColor: '#f87979',
                        data: []
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
                hover: {
                    animationDuration: 0
                },
                responsiveAnimationDuration: 0
            },
            fields: [
                { key: 'created_at', label: "Date", sortable: true },
                { key: 'external_order_id', label: "Order No.", sortable: true },
                { key: 'package_price', label: "Package Price" }
            ],
            log_fields: [
                { key: 'created_at', label: "Date"},
                { key: 'event', label: "Event" },
                { key: 'viwes_total', label: "Views" },
                { key: 'status_comment', label: "Note" }
            ]
        }
    },

    watch: {
        '$route' : 'load',
        similarOrdersCurrent_page(val) {
            this.similarOrdersSkip = (this.similarOrdersTake * val) - this.similarOrdersTake;
            this.getOrders();
        }
    },
    components: {
        navAdmin, 
        lineChart,
        pauseWindow,
        cancelWindow,
        updateOrder,
        preCancelOrder,
        startPromoWindow,
        updatePreCanceled
    },
    mixins: [youtubeVideoPreview, orderProcessStages, cropText, timeZone],
    methods: {
        ...mapActions([
            'fectchDiscountByOrderId',
        ]),
        getStripeSessions() {
            this.$http({ url: 'order/' + this.$route.params.order_id + '/payment', method: 'GET'})
                .then(res => {
                    this.stripe_sessions = res.data.order_payment_sessions;
                });
        },
        getOrder() {
            this.$http({ url: 'order/' + this.$route.params.order_id, method: 'GET'})
                .then(res => {
                    this.order = res.data.order;
                    this.getYouTubeVideoInfoFromUrl(this.order.youtube_video_url);
                });
        },
        getOrders() {
            this.$http({ url: 'orders/' + this.$route.params.order_id + "/email/skip/" + this.similarOrdersSkip + '/take/' + this.similarOrdersTake, method: 'GET'})
                .then(res => {
                    this.customer_orders = res.data.orders;
                    this.similarOrdersTotal = res.data.total;
                });
        },
        changeStatusOfNotification() {
            this.$http.put('order/update/toggle-customer-notification', {
                'order_id': this.$route.params.order_id
            }).then(() => {
                this.getOrder()
            }); 
        },
        getOrderProcessLog() {
            this.$http({ url: 'order/' + this.$route.params.order_id + '/logs', method: 'GET'})
                .then(res => {
                    this.mapLogsToTableValue(res.data.order_logs);
                    this.mapCLogsToChartValue(res.data.order_logs);
                    this.setLikes(res.data.order_logs);
                    this.setComments(res.data.order_logs);
                    this.setStatusComment(res.data.order_logs);
                });
        },
        mapCLogsToChartValue(logs_arr) {

            const maxPoints = 40;

            let step = 1;

            if(logs_arr.length > maxPoints) {
                step = (logs_arr.length - (logs_arr.length % maxPoints)) / maxPoints;
            } 

            this.chartdataViews.labels = [];
            this.chartdataViews.datasets = [];
            this.chartdataLikesAndComments.labels = [];
            this.chartdataLikesAndComments.datasets = [];

            const labelsIndexes = [];

            for(let i = 0; i < logs_arr.length; i += step) {

                labelsIndexes.push(i);

                const log = logs_arr[i],   
                    date = this.convertBrouserTZobj(log.created_at);
                
                const dateStr = (date.getMonth() + 1) + "."+ date.getDate() + " " + date.getHours() + ":" + (date.getMinutes() == 0 ? "00" : date.getMinutes());
                
                this.chartdataViews.labels.push(dateStr);
                this.chartdataLikesAndComments.labels.push(dateStr);
            }

        
            const likes = {
                label: 'Likes',
				data: [],
				borderColor: 'rgba(255, 19, 71, 1)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				fill: false,
				cubicInterpolationMode: 'monotone'
            };

            for(let i = 0; i < labelsIndexes.length; i++) {
                likes.data.push(logs_arr[labelsIndexes[i]].likes);
            }

            const comments = {
                label: 'Comments',
				data: [],
				borderColor: 'rgba(238, 130, 238, 1)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				fill: false,
				cubicInterpolationMode: 'monotone'
            };

            for(let i = 0; i < labelsIndexes.length; i++) {
                comments.data.push(logs_arr[labelsIndexes[i]].coments);
            }

            const pending = {
                label: 'Pending',
				data: [],
				borderColor: 'rgba(0, 0, 0, 0.5)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				fill: false,
				cubicInterpolationMode: 'monotone'
            };

            for(let i = 0; i < labelsIndexes.length; i++) {
                const index = labelsIndexes[i];
                const stage = logs_arr[index].stage;

                if(index < logs_arr.length - 1 && stage != this.stages.pending && index != 0 && logs_arr[labelsIndexes[i - 1]].stage == this.stages.pending) {
                    pending.data.push(logs_arr[index].views_total);
                } else {
                    pending.data.push(stage == this.stages.pending || stage == this.stages.notPaid ? logs_arr[index].views_total : NaN);
                }
            }

            const processing = {
                label: 'In process',
				data: [],
				borderColor: 'rgb(75, 192, 192)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				fill: false,
				cubicInterpolationMode: 'monotone'
            };

            for(let i = 0; i < labelsIndexes.length; i++) {
                const index = labelsIndexes[i];
                const stage = logs_arr[index].stage;
                
                if(index < logs_arr.length - 1 && stage != this.stages.inProcess && index != 0 && logs_arr[labelsIndexes[i - 1]].stage == this.stages.inProcess) {
                    processing.data.push(logs_arr[index].views_total);
                } else {
                    processing.data.push(stage == this.stages.inProcess || stage == this.stages.done ? logs_arr[index].views_total : NaN);
                }
            }

            const paused = {
                label: 'Paused',
				data: [],
				borderColor: 'rgb(153, 102, 255)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				fill: false,
				cubicInterpolationMode: 'monotone'
            };

            for(let i = 0; i < labelsIndexes.length; i++) {
                const index = labelsIndexes[i];
                const stage = logs_arr[index].stage;
                
                if(index < logs_arr.length - 1 && stage != this.stages.paused && index != 0 && logs_arr[labelsIndexes[i - 1]].stage == this.stages.paused) {
                    paused.data.push(logs_arr[index].views_total);
                } else {
                    paused.data.push(stage == this.stages.paused ? logs_arr[index].views_total : NaN);
                }
            }


            this.chartdataLikesAndComments.datasets = [likes,  comments];
            this.$refs.likesAndCommentsChart.render();
            this.chartdataViews.datasets = [pending, processing, paused];
            this.$refs.viewsChart.render();
        },
        mapLogsToTableValue(logs_arr) {
            const change_process_events = [];
            
            for(let i = 0; i < logs_arr.length; i++) {
                if(i == 0) {
                    change_process_events.push(logs_arr[i]);
                    continue;
                }

                if(change_process_events[change_process_events.length - 1].stage != logs_arr[i].stage) {
                    change_process_events.push(logs_arr[i - 1]);
                    change_process_events.push(logs_arr[i]);
                }
            }
            
            this.logs_events = [];

            for(let i = 0; i < change_process_events.length; i++) {
                let curr_item = change_process_events[i];
                let next_item = change_process_events[i + 1];

                if(next_item != undefined && curr_item.stage != next_item.stage) {

                    this.logs_events.push({
                        date: next_item.created_at,
                        from: this.stageTextArr[curr_item.stage],
                        to: this.stageTextArr[next_item.stage],
                        views_total: next_item.views_total,
                        status_comment: next_item.status_comment
                    });

                    i++;
                }
            }
            
            this.process_logs = logs_arr;
        },
        setLikes(logs_arr) {
            const selection = logs_arr.filter(log => log.stage == this.stages.inProcess || log.stage == this.stages.done);
            if(selection.length > 0) {
                const orderedSelection = selection.sort((a, b) => a.created_at > b.created_at ? 1 : -1);

                this.likesBeforePromo = orderedSelection[0].likes;
                this.linkesCurrent =  orderedSelection[orderedSelection.length - 1].likes;
            }
        },
        setComments(logs_arr) {
            const selection = logs_arr.filter(log => log.stage == this.stages.inProcess || log.stage == this.stages.done);
            if(selection.length > 0) {
                const orderedSelection = selection.sort((a, b) => a.created_at > b.created_at ? 1 : -1);

                this.commentsBeforePromo = orderedSelection[0].coments;
                this.commentsCurrent =  orderedSelection[orderedSelection.length - 1].coments;
            }
        },
        setStatusComment(logs_arr) {
            if(logs_arr.length > 0) {
                const orderedSelection = logs_arr.sort((a, b) => a.created_at > b.created_at ? 1 : -1);
                this.statusComment =  orderedSelection[orderedSelection.length - 1].status_comment;
            }
        },
        updateOrder() {
            this.$refs.updateOrderNotPaid.orderId = this.$route.params.order_id;
            this.$refs.updateOrderNotPaid.show();
        },
        updatePreCanceledOrder() {
            this.$refs.updatePreCanceled.orderId = this.$route.params.order_id;
            this.$refs.updatePreCanceled.show();
        },
        setToPromotion(orderId, showOptions) {
            this.$refs.startPromo.showOptions = showOptions;
            this.$refs.startPromo.orderId = orderId;
            this.$refs.startPromo.show();
        },
        pausePromotion(orderId) {
            this.$refs.pausePromo.orderId = orderId;
            this.$refs.pausePromo.show();
        },
        cancelPromotion(orderId, showOptions) {
            this.$refs.cancelPromo.showOptions = showOptions;
            this.$refs.cancelPromo.orderId = orderId;
            this.$refs.cancelPromo.show();
        },
        preCancel(orderId,showOptions) {
            this.$refs.preCancelOrder.showOptions = showOptions;
            this.$refs.preCancelOrder.orderId = orderId;
            this.$refs.preCancelOrder.show();
        },
        load() {
            this.getOrder();
            this.getOrderProcessLog();
            this.getOrders();
            this.getStripeSessions();
            this.fectchDiscountByOrderId(this.$route.params.order_id);
        },
        loadAfterUpdate(order_id) {
            if(order_id) {
                this.load();
            }   
        },
        getPoints(labelsIndexes, logs_arr, stage, step) {
            const result = [];

            for(let i = 0; i < labelsIndexes.length; i++) {
                const index = labelsIndexes[i];
                const logStage = logs_arr[index].stage;

                if(index < logs_arr.length - 1 && stage != stage && index != 0 && logs_arr[index - step].stage == this.stages.pending) {
                    result.push(logs_arr[index].views_total);
                } else {
                    result.push(logStage == stage || logStage == this.stages.notPaid ? logs_arr[index].views_total : NaN);
                }
            }

            return result;
        }
        
    },
    mounted() {
        this.current_page = this.$route.query.page !== undefined ? +this.$route.query.page : this.current_page;
        this.activeStage =  this.$route.query.stage !== undefined ? +this.$route.query.stage : this.activeStage;
        
        this.$router.push({ name: 'Order', params: { order_id: this.$route.params.order_id }});

        this.load();
    }
}
</script>
<style lang="scss" scoped>
* {
    font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>